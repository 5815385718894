<template>
  <div class="detailDialog">
    <div class="booking-left-menu" v-if="shipmentView.quotationId">
      <div
        class="booking-button button"
        :class="{ 'is-check': detailDialogType == 1 || xianshitype == '1' }"
        @click="(detailDialogType = '1') && (xianshitype = '')"
      >
        {{ $t("lang.NText83") }}
      </div>
      <div
        class="quotation-button button"
        :class="{ 'is-check': detailDialogType == 2 || xianshitype == '2' }"
        @click="(detailDialogType = '2') && (xianshitype = '')"
      >
        {{ $t("lang.QText86") }}
      </div>
      <div
        class="quotation-button button"
        :class="{ 'is-check': detailDialogType == 3 || xianshitype == '3' }"
        @click="(detailDialogType = '3') && (xianshitype = '')"
      >
        <span v-if="bookingId">{{ $t("lang.BText35") }}</span>
        <span v-if="shipmentView.shipmentNo">{{ $t("lang.NText121") }}</span>
      </div>
      <!-- <div
        class="chatHistory-button button"
        :class="{ 'is-check': detailDialogType == 4 || xianshitype == '4' }"
        @click="chatHistoryClick()"
      >
        Chat history
      </div> -->
    </div>
    <div
      class="TaskLog-title"
      v-if="(detailDialogType != '3' || xianshitype != '3') && !iShipment"
    >
      <div
        class="taskType"
        v-if="detailDialogType == '1' || xianshitype == '1'"
      >
        {{ stepsData.head ? stepsData.head.detailDialogType : "B" }}
      </div>
      <div
        class="taskType"
        v-if="
          (shipmentView.shipmentNo && detailDialogType == '3') ||
          (shipmentView.shipmentNo && xianshitype == '3')
        "
      >
        S
      </div>
      <div
        class="TaskLog-title-ID"
        v-clipboard:copy="stepsData.head ? stepsData.head.taskID : '测试'"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
      >
        <span v-if="detailDialogType == '1' || xianshitype == '1'"
          >{{ $t("lang.BText63") }}：</span
        >
        <span v-if="detailDialogType == '2' || xianshitype == '2'">QID：</span>
        <span v-if="bookingId">BID：</span>

        <span style="color: #299be4">{{
          stepsData.head ? stepsData.head.taskID : "测试"
        }}</span
        ><svg-icon icon-class="copy"></svg-icon>
      </div>
    </div>
    <div
      class="shipmentTitle"
      v-if="
        (shipmentView.shipmentNo && detailDialogType == '3') ||
        (shipmentView.shipmentNo && xianshitype == '3')
      "
    >
      <div
        class="TaskLog-title-ID"
        v-clipboard:copy="shipmentView.shipmentNo"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
      >
        <div class="taskType">S</div>
        <span style="color: #299be4">{{ shipmentView.shipmentNo }}</span
        ><svg-icon icon-class="copy"></svg-icon>
      </div>
      <k-second-header
        :data="shipmentMenu"
        :clickchange="false"
        @handleSelect="handleSelect"
        class="shipmentHeader"
      ></k-second-header>
    </div>
    <div class="dialog-main">
      <div
        class="bookingReviewMainTitle"
        v-if="
          (detailDialogType == '2' && bookingId) ||
          (xianshitype == '2' && bookingId)
        "
      >
        <div
          class="Ocean-freight-FCL"
          :class="{
            'Ocean-freight-FCL2': detailDialogType == '2' || xianshitype == '2',
          }"
        >
          <div class="icon-div">
            <svg-icon
              icon-class="oceanFreight"
              v-show="detailDialogType == 3 || xianshitype == '3'"
            ></svg-icon>
            <svg-icon
              icon-class="oceanFreight2"
              v-show="detailDialogType == 2 || xianshitype == '2'"
            ></svg-icon>
            <div class="icon-line"></div>
            <svg-icon
              v-if="shipmentView.containerMode == 'FCL'"
              icon-class="FCL"
            ></svg-icon>
          </div>
          <div class="title">
            {{ $t("lang.QText1") }} <br />
            FCL
          </div>
        </div>
        <div
          class="shippingAuthorization"
          v-if="
            (detailDialogType == '1' && bookingId) ||
            (xianshitype == '1' && bookingId)
          "
        >
          <div class="text">{{ $t("lang.BText122") }}</div>
        </div>
        <div
          class="shippingAuthorization"
          :class="{ unClick: !hasQuotation }"
          v-if="detailDialogType == '2' || xianshitype == '2'"
        >
          <div class="text">{{ $t("lang.BText65") }}</div>
        </div>
      </div>
      <dw-steps
        v-if="detailDialogType == '1' || xianshitype == '1'"
        :stepsData="stepsData"
        class="dwSteps"
      ></dw-steps>
      <div
        class="quotationMain"
        v-if="detailDialogType == '2' || xianshitype == '2'"
      >
        <quotation-view
          v-if="hasQuotation"
          :listData="quotationList"
        ></quotation-view>
        <template v-if="!hasQuotation">
          <div class="kDialog-main">
            <el-upload
              class="upload"
              drag
              multiple
              :auto-upload="false"
              :show-file-list="false"
              :on-change="upLoad"
              :action="baseUrl + '/crm/edoc/upload'"
            >
              <div class="el-upload__text">{{ $t("lang.BText71") }}</div>
              <div class="button">{{ $t("lang.QText88") }}</div>
            </el-upload>
            <div class="offlineFile">
              <div class="fileItem">
                <svg-icon icon-class="note"></svg-icon>
                <div class="fileName">{{ $t("lang.NText87") }}</div>
              </div>
              <div class="fileList">1/3</div>
            </div>
            <div class="fileSearch">
              <el-input :placeholder="$t('lang.NText322')"></el-input>
              <svg-icon icon-class="delete"></svg-icon>
            </div>
          </div>
        </template>
      </div>
      <bookingview
        @editBooking="clickEdit"
        :bookingId="bookingId"
        v-if="
          (detailDialogType == '3' && bookingId) ||
          (xianshitype == '3' && bookingId)
        "
        :showButton="false"
      ></bookingview>
      <div
        class="shipmentView"
        v-if="
          (detailDialogType == '3' && shipmentView.shipmentNo) ||
          (xianshitype == '3' && shipmentView.shipmentNo)
        "
        v-loading="loading"
      >
        <div class="topData">
          <div class="topDataLeft">
            <div class="Ocean-freight-FCL">
              <div class="icon-div">
                <svg-icon
                  icon-class="oceanFreight"
                  v-show="detailDialogType == 3 || xianshitype == '3'"
                ></svg-icon>
                <svg-icon
                  icon-class="oceanFreight2"
                  v-show="detailDialogType == 2 || xianshitype == '2'"
                ></svg-icon>
                <div class="icon-line"></div>
                <svg-icon
                  v-if="shipmentView.containerMode == 'FCL'"
                  icon-class="FCL"
                ></svg-icon>
              </div>
              <div class="title">
                {{ $t("lang.QText1") }} <br />
                FCL
              </div>
            </div>
            <div class="topDataTitle">{{ shipmentTitle }}</div>
          </div>
          <div class="topDataRight">
            <div class="mainData">
              <div class="mainData-title">{{ $t("lang.NText122") }}:</div>
              <div class="mainData-value">{{ shipmentView.mblNo }}</div>
            </div>
            <div class="mainData">
              <div class="mainData-title">{{ $t("lang.NText123") }}:</div>
              <div class="mainData-value">{{ shipmentView.carrier }}</div>
            </div>
            <div class="mainData">
              <div class="mainData-title">{{ $t("lang.NText124") }}:</div>
              <div
                class="mainData-value"
                :title="shipmentView.vessel + shipmentView.voyage"
              >
                {{ shipmentView.vessel }} {{ shipmentView.voyage }}
              </div>
            </div>
            <div class="mainData">
              <div class="mainData-title">{{ $t("lang.NText125") }}:</div>
              <div class="mainData-value">{{ shipmentView.hblNo }}</div>
            </div>
            <div class="mainData">
              <div class="mainData-title">{{ $t("lang.QText26") }}:</div>
              <div class="mainData-value" :title="shipmentView.container">
                {{ shipmentView.container }}
              </div>
            </div>
            <div class="mainData">
              <div class="mainData-title">{{ $t("lang.NText126") }}:</div>
              <div class="mainData-value">{{ shipmentView.incoterm }}</div>
            </div>
          </div>
        </div>
        <div class="shipmentViewMain">
          <!-- <keep-alive>
            <router-view />
          </keep-alive> -->
          <basic-info
            v-if="handleSelectStr == '0' && state && shipmentView.shipmentId"
            :shipmentData="shipmentView"
          ></basic-info>
          <routing
            v-if="handleSelectStr == '1' && state && shipmentView.shipmentId"
            :shipmentData="shipmentView"
          ></routing>
          <container
            v-if="handleSelectStr == '2' && state && TrackClickArrState"
            :shipmentData="shipmentView"
          ></container>
          <e-doc
            v-if="handleSelectStr == '3' && state && shipmentView.shipmentId"
            :shipmentData="shipmentView"
          ></e-doc>
        </div>
      </div>
      <div
        class="chatHistory"
        v-if="detailDialogType == '4' && xianshitype == ''"
      >
        <history :toSendMsg="toSendMsg" :other="false"></history>
      </div>
    </div>
  </div>
</template>

<script>
import test from "../../test/test";
import dwSteps from "../../components/dwSteps/dwSteps.vue";
import bookingview from "../Booking/review.vue";
import FillInList from "../Booking/fillInList.vue";
import quotationView from "../Quotation/quotationView.vue";
import KSecondHeader from "../../components/kHeader/kSecondHeader.vue";
import basicInfo from "../Shipment/basicInfo.vue";
import routing from "../Shipment/routing.vue";
import container from "../Shipment/container.vue";
import eDoc from "../Shipment/eDoc.vue";
import history from "../../components/dwChat/history.vue";
import global from "../../utils/global";
export default {
  components: {
    dwSteps,
    bookingview,
    FillInList,
    quotationView,
    KSecondHeader,
    basicInfo,
    routing,
    container,
    eDoc,
    history,
  },
  props: {
    stepsData: {
      type: Object,
      default: function () {
        return {};
      },
    },
    iShipment: {
      type: Boolean,
      default: () => false,
    },
    shipmentData: {
      type: Object,
      default: function () {
        return {};
      },
    },
    bookingId: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    xianshitype: {
      type: String,
      default: "",
    },
    toSendMsgObj: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      handleSelectStr: "0",
      loading: true,
      detailDialogType: "",
      toSendMsg: {},
      quotationList: test.QuotationData,
      showFillIn: false,
      hasQuotation: false,
      TrackClickArrState: false,
      state: false,
      headFile: null,
      shipmentMenu: [
        {
          menuName: "Basic info",
          link: "/Shipment/basicInfo",
          extend: {
            extendS3: "Basic info",
          },
        },
        {
          menuName: "Routing",
          link: "/Shipment/Routing",
          extend: {
            extendS3: "Routing",
          },
        },
        {
          menuName: "Container",
          link: "/Shipment/Container",
          extend: {
            extendS3: "Container",
          },
        },
        // {
        //   menuName: "eDoc",
        //   link: "/Shipment/eDoc",
        // },
      ],
      shipmentTitle: "",
      shipmentView: {},
      TrackClickArr: [],
      baseUrl: null,
    };
  },
  created() {
    this.init();
  },
  mounted: function () {},
  methods: {
    init() {
      this.baseUrl = global.baseUrl;
      this.detailDialogType = this.type;
      if (this.shipmentData.shipmentNo) {
        this.getShipmentData();
      }
    },

    getShipmentData() {
      this.$store.commit("api/SET_LOADING", true);
      this.$ajax
        .cPost(this.source.shipment_api.view, {
          shipmentNo: this.shipmentData.shipmentNo,
        })
        .then((res) => {
          if (res.data.result == "true") {
            this.shipmentView = res.data;
            this.shipmentView.shipmentId = this.shipmentData.shipmentId;
            this.shipmentView.consoleId = this.shipmentData.consoleId;
            this.$store.commit("api/SET_SHIPMENT", this.shipmentView);
            if (this.detailDialogType == "3") this.TrackClick();
          }
          this.$store.commit("api/SET_LOADING", false);
        });
    },
    clickEdit() {},
    onCopy() {
      this.$notify({
        title: this.$t("lang.SText125"),
        type: "success",
        offset: 100,
      });
    },
    onError() {},
    upLoad(file, fileList) {
      this.headFile = file;
      console.log(file);
      this.hasQuotation = true;
    },
    handleSelect(e) {
      this.handleSelectStr = e;
      this.shipmentTitle = this.shipmentMenu[e].menuName;
      return;
      this.$router.push({ path: this.shipmentMenu[e].link });
    },
    TrackClick() {
      this.$ajax
        .cPost(this.source.tracking_api.queryTracking, {
          selectNo: this.shipmentData.shipmentNo,
        })
        .then((res) => {
          if (res.data.result == "true") {
            if (
              res.data.tracking[0]["trackingSub"][0]["containerNumberListMap"]
                .length
            ) {
              this.TrackClickArrState = true;
              sessionStorage.setItem(
                "TrackClickArr",
                JSON.stringify(
                  res.data.tracking[0]["trackingSub"][0][
                    "containerNumberListMap"
                  ]
                )
              );
            } else {
              this.TrackClickArrState = false;
            }
            this.state = true;
            this.loading = false;
          } else {
            this.$notify({
              title: res.data.message,
              type: "error",
              offset: 100,
            });
          }
        });
    },
    chatHistoryClick() {
      this.detailDialogType = 4;
      this.xianshitype = "";
      if (!this.toSendMsgObj.bid) return;
      this.chatDrawerOpen(this.toSendMsgObj);
    },
    chatDrawerOpen(obj) {
      this.$ajax.cPost(this.source.account_api.toSendMsg, obj).then((res) => {
        if (res.data.result == "true") {
          this.toSendMsg = res.data.msgMain;
        } else {
          this.$notify({
            title: res.data.message,
            type: "error",
            offset: 100,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/base.scss";
.detailDialog {
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
  }
  .el-dialog__close {
    display: none;
  }
  .booking-left-menu {
    position: fixed;
    top: 200px;
    left: 15%;
    margin-left: -44px;
    .button {
      width: 146px;
      height: 60px;
      transform: rotate(90deg);
      -ms-transform: rotate(90deg); /* IE 9 */
      -moz-transform: rotate(90deg); /* Firefox */
      -webkit-transform: rotate(90deg); /* Safari 和 Chrome */
      -o-transform: rotate(90deg);
      font-size: 20px;
      border: 2px solid $mainColor;
      line-height: 60px;
      background-color: #fff;
      color: $mainColor;
      cursor: pointer;
    }
    .booking-button {
      margin-top: 30px;
    }
    .quotation-button {
      margin-top: 100px;
    }
    .chatHistory-button {
      margin-top: 100px;
    }
    .button:hover,
    .is-check {
      background-color: $mainColor;
      color: $background2;
    }
  }
  .TaskLog-title {
    text-align: left;
    // border-bottom: 2px solid $mainColor;
    width: 1120px;
    margin: auto;
    display: flex;
    padding: 30px 0 10px 0;
    font-size: 30px;
    width: 1020px;

    .taskType {
      height: 36px;
      width: 36px;
      line-height: 36px;
      text-align: center;
      font-size: 20px;
      border: 2px solid $mainColor;
      color: $mainColor;
      margin-right: 20px;
    }
    .TaskLog-title-ID {
      color: $mainColor;
      line-height: 45px;
      .svg-icon {
        font-size: 20px;
        color: $fontColor1;
        padding-left: 30px;
        margin-top: 20px;
      }
    }
  }
  .shipmentTitle {
    text-align: left;
    width: 960px;
    margin: auto;
    display: flex;
    height: 59px;
    font-size: 30px;
    border-bottom: 4px solid $mainColor;
    margin-top: 40px;
    justify-content: space-between;
    .taskType {
      height: 36px;
      width: 36px;
      line-height: 36px;
      text-align: center;
      font-size: 20px;
      border: 2px solid $mainColor;
      color: $mainColor;
      margin-right: 20px;
    }
    .TaskLog-title-ID {
      color: $mainColor;
      line-height: 45px;
      display: flex;
    }
    .svg-icon {
      font-size: 20px;
      color: $fontColor1;
      padding-left: 30px;
      padding-top: 10px;
    }
    .shipmentHeader {
      .el-menu .is-active {
        height: 60px !important;
      }
      .el-menu .el-menu-item {
        height: 60px !important;
      }
    }
  }
  .dialog-main {
    min-height: 700px;
    padding-bottom: 50px;
    width: 960px;
    margin: auto;
    .dwSteps {
      margin-top: 70px;
      width: 100%;
    }
    .quotationTitle {
      padding-top: 20px;
    }

    .bookingReviewMainTitle {
      width: 960px;
      margin: auto;
      display: flex;
      justify-content: space-between;
      border-bottom: 4px solid $mainColor;
      padding-bottom: 20px;
      margin-bottom: 20px;
      padding-top: 20px;
    }
    .Ocean-freight-FCL {
      height: 60px;
      display: flex;
      .icon-div {
        height: 60px;
        width: 120px;
        display: flex;
        align-items: center;
        border: 2px solid $mainColor;
        .svg-icon {
          height: 30px;
          width: 30px;
          padding: 0 15px;
          color: $mainColor;
          fill: $mainColor;
          stroke: $mainColor;
        }
      }
      .icon-line {
        width: 1px;
        height: 30px;
        background: $mainColor;
      }
      .title {
        margin-left: 20px;
        font-size: 30px;
        color: $mainColor;
        font-weight: bold;
        line-height: 34px;
        text-align: left;
        width: 210px;
      }
    }

    .shippingAuthorization {
      height: 60px;
      width: 200px;
      line-height: 20px;
      text-align: center;
      background-color: $mainColor;
      color: #fff;
      font-size: 18px;
      word-break: normal;
      cursor: pointer;
      display: table;
      .text {
        display: table-cell;
        vertical-align: middle;
      }
    }
    .unClick {
      background-color: $borderColor1;
      color: $fontColor2;
    }
    .el-upload-dragger {
      width: 860px;
      border: 0;
      height: 492px;
      position: relative;
      background: #fff;
      z-index: 100;
      .el-upload__text {
        font-size: 60px;
        color: $fontColor2;
        line-height: 65px;
        text-align: center;
        margin-top: 100px;
      }
      .button {
        background-color: $mainColor;
        color: #fff;
        width: 400px;
        height: 64px;
        line-height: 64px;
        top: 220px;
        font-size: 20px;
        margin: 80px auto;
      }
    }
    .Ocean-freight-FCL2 {
      display: flex;
      .icon-div {
        height: 60px;
        width: 120px;
        display: flex;
        align-items: center;
        background-color: $mainColor;
        .svg-icon {
          height: 30px;
          width: 30px;
          padding: 0 15px;
          color: #fff;
          fill: #fff;
          stroke: #fff;
        }
      }
      .icon-line {
        background: #fff;
      }
      .title {
        margin-left: 20px;
        font-size: 30px;
        color: $mainColor;
        font-weight: bold;
        line-height: 34px;
        text-align: left;
      }
    }
    .offlineFile {
      border-bottom: 4px solid $mainColor;
      display: flex;
      justify-content: space-between;
      .fileItem {
        display: flex;
        color: $fontColor2;
        line-height: 30px;
      }
    }
    .fileSearch {
      width: 300px;
      margin: 30px 10px;
      display: flex;
      align-items: center;
      .svg-icon {
        padding: 0 20px;
      }
    }
  }

  .shipmentHeader {
    height: 30px;
  }

  .shipmentView {
    .topData {
      display: flex;
      border-bottom: 2px solid $mainColor;
      padding: 20px 0;
      .topDataLeft {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .topDataTitle {
          font-size: 60px;
          color: $borderColor1;
          text-align: left;
        }
      }
    }
    .mainData {
      display: flex;
      font-size: 20px;
      line-height: 30px;
      .mainData-title {
        color: $fontColor3;
        width: 180px;
        text-align: right;
        margin-right: 10px;
      }
      .mainData-value {
        color: $mainColor;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 315px;
        text-align: left;
      }
    }
  }
}
</style>