<template>
  <div class="bookingDialog" v-loading="loading">
    <div class="booking-left-menu">
      <div
        class="booking-button button"
        :class="{ 'is-check': detailDialogType == 1 }"
        @click="detailDialogType = '1'"
      >
        {{ $t("lang.BText20") }}
      </div>
      <div
        class="quotation-button button"
        :class="{ 'is-check': detailDialogType == 3 }"
        @click="detailDialogType = '3'"
      >
        {{ $t("lang.BText35") }}
      </div>
      <div
        class="quotation-button button"
        :class="{ 'is-check': detailDialogType == 2 }"
        @click="detailDialogType = '2'"
      >
        {{ $t("lang.BText21") }}
      </div>

      <!-- <div
        class="chatHistory-button button"
        :class="{ 'is-check': detailDialogType == 4 }"
        @click="chatHistoryClick()"
      >
        Chat history
      </div> -->
    </div>
    <div class="TaskLog-title">
      <template v-if="detailDialogType == '1' && stepsData.head"
        ><div class="taskType">
          {{ stepsData.head.detailDialogType }}
        </div>
        <div
          class="TaskLog-title-ID"
          v-clipboard:copy="stepsData.head ? stepsData.head.taskID : '测试'"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
        >
          <span>{{ $t("lang.BText63") }}：</span>
          <span style="color: #299be4">{{
            stepsData.head ? stepsData.head.taskID : "测试"
          }}</span
          ><svg-icon icon-class="copy"></svg-icon></div
      ></template>
      <template v-if="detailDialogType == '2' && bookingView.quotation">
        <div class="taskType">Q</div>
        <div
          class="TaskLog-title-ID"
          v-clipboard:copy="bookingView.quotation.quotationNo"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
        >
          <span>{{ $t("lang.BText64") }}：</span>
          <span style="color: #299be4">{{
            bookingView.quotation.quotationNo
          }}</span
          ><svg-icon icon-class="copy"></svg-icon>
        </div>
        <!-- 已提交和等待生成报价 -->
        <div
          class="statusForhtml"
          v-if="
            bookingView.quotation.statusForhtml == 'Quote' ||
            bookingView.quotation.statusForhtml == 'Submit'
          "
        >
          {{ bookingView.quotation.statusForhtml }}
        </div>
        <!-- 已经确认报价 -->
        <div
          class="statusForhtml"
          v-else-if="
            bookingView.quotation.amountConfirmFlag == 1 &&
            bookingView.quotation.state == 20
          "
        >
          {{ bookingView.quotation.statusForhtml }}
        </div>
        <!-- 生成报价等待确认 -->
        <div
          class="shippingAuthorization"
          @click="confirmQuotationCharges"
          v-else-if="
            bookingView.quotation.amountConfirmFlag != 1 &&
            bookingView.quotation.state == 20
          "
        >
          <div class="text">{{ $t("lang.BText65") }}</div>
        </div>
        <div
          class="upload"
          @click="downloadPdf()"
          v-show="bookingView.quotation.state == 20 && type == 2"
        >
          <i class="el-icon-download"></i></div
      ></template>
      <template v-if="detailDialogType == '3'">
        <div class="taskType">B</div>
        <div
          class="TaskLog-title-ID"
          v-clipboard:copy="bookingView.bookingNo"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
        >
          <span>{{ $t("lang.BText66") }}：</span>
          <span style="color: #299be4">{{ bookingView.bookingNo }}</span
          ><svg-icon icon-class="copy"></svg-icon>
        </div>
        <!-- 已提交和等待生成报价 -->
        <div class="statusForhtml">
          <span v-if="bookingView.bookingStatus == '10'">{{
            $t("lang.BText10")
          }}</span>
          <span
            v-if="
              bookingView.bookingStatus == '202' ||
              bookingView.bookingStatus == '101' ||
              bookingView.bookingStatus == '201'
            "
            >{{ $t("lang.BText11") }}</span
          >
          <span v-if="bookingView.bookingStatus == '40'">{{
            $t("lang.BText12")
          }}</span>
          <span v-if="bookingView.bookingStatus == '50'">{{
            $t("lang.BText14")
          }}</span>
          <span v-if="bookingView.bookingStatus == '70'">{{
            $t("lang.BText15")
          }}</span>
        </div>
        <!-- 生成报价等待确认 -->
        <div
          class="shippingAuthorization"
          @click="shippingAuthorize"
          v-if="bookingView.bookingStatus == '30'"
        >
          <div class="text">{{ $t("lang.BText70") }}</div>
        </div>
        <div
          class="upload"
          @click="downloadPdf()"
          v-show="bookingView.state == 20 && type == 2"
        >
          <i class="el-icon-download"></i></div
      ></template>
    </div>

    <div
      class="shipment-title"
      v-if="
        (shipmentView.shipmentNo && detailDialogType == '3') ||
        (shipmentView.shipmentNo && xianshitype == '3')
      "
    >
      <div
        class="TaskLog-title-ID"
        v-clipboard:copy="shipmentView.shipmentNo"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
      >
        <div class="taskType">S</div>
        <span style="color: #299be4">{{ shipmentView.shipmentNo }}</span
        ><svg-icon icon-class="copy"></svg-icon>
      </div>
      <k-second-header
        :data="shipmentMenu"
        :clickchange="false"
        @handleSelect="handleSelect"
        class="shipmentHeader"
      ></k-second-header>
    </div>
    <div class="dialog-main">
      <dw-steps
        v-if="detailDialogType == '1'"
        :stepsData="stepsData"
        class="dwSteps"
      ></dw-steps>
      <div class="quotationMain" v-if="detailDialogType == '2'">
        <quotation-view
          v-if="
            bookingView.quotation &&
            bookingView.quotation.charges &&
            bookingView.quotation.charges.length > 0
          "
          :quotationData="JSON.stringify(bookingView.quotation)"
        ></quotation-view>
        <template
          v-if="
            !bookingView.quotation ||
            !bookingView.quotation.charges ||
            (bookingView.quotation.charges &&
              bookingView.quotation.charges.length == 0)
          "
        >
          <div class="kDialog-main">
            <div class="el-upload__text">{{ $t("lang.BText71") }}</div>
            <div class="offlineFile-line1">
              {{ $t("lang.BText72") }}
            </div>
            <el-upload
              :action="baseUrl + '/crm/edoc/upload'"
              class="uploaddiv"
              multiple
              :limit="3"
              :on-change="changeFile"
              :data="uploadData"
              :show-file-list="false"
              :on-success="upLoadSuccess"
              :on-error="upLoadError"
            >
              <div class="upload">
                <dw-button type="primarySmall">{{
                  $t("lang.BText73")
                }}</dw-button>
                <div class="file-type">{{ $t("lang.BText74") }}</div>
              </div>
            </el-upload>
            <div class="offlineFile" v-if="tableDatamy.length > 0">
              <div class="fileItem">
                <div class="fileName">
                  {{ tableDatamy[0].documentName }}.{{
                    tableDatamy[0].documentType
                  }}
                </div>
                <svg-icon icon-class="delete" @click="delectFile()"></svg-icon>
              </div>
            </div>
            <!-- <div class="fileSearch">
            <el-input placeholder="Document name"></el-input>
            <svg-icon icon-class="delete"></svg-icon>
          </div> -->
          </div>
        </template>
      </div>
      <bookingview
        :bookingData="bookingView"
        v-if="detailDialogType == '3'"
        :showButton="false"
      ></bookingview>

      <div
        class="chatHistory"
        v-if="detailDialogType == '4' && xianshitype == ''"
      >
        <history :toSendMsg="toSendMsg" :other="false"></history>
      </div>
    </div>
  </div>
</template>

<script>
import test from "../../test/test";
import dwSteps from "../../components/dwSteps/dwSteps.vue";
import bookingview from "./review.vue";
import FillInList from "./fillInList.vue";
import quotationView from "../Quotation/quotationView.vue";
import KSecondHeader from "../../components/kHeader/kSecondHeader.vue";
import basicInfo from "../Shipment/basicInfo.vue";
import routing from "../Shipment/routing.vue";
import container from "../Shipment/container.vue";
import eDoc from "../Shipment/eDoc.vue";
import history from "../../components/dwChat/history.vue";
import global from "../../utils/global";
export default {
  components: {
    dwSteps,
    bookingview,
    FillInList,
    quotationView,
    KSecondHeader,
    basicInfo,
    routing,
    container,
    eDoc,
    history,
  },
  props: {
    iShipment: {
      type: Boolean,
      default: () => false,
    },
    bookingView: {
      type: Object,
      default: function () {
        return {};
      },
    },
    bookingId: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "3",
    },
    xianshitype: {
      type: String,
      default: "",
    },
    toSendMsgObj: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      handleSelectStr: "0",
      loading: false,
      detailDialogType: "3",
      toSendMsg: {},
      quotationList: test.QuotationData,
      showFillIn: false,
      hasQuotation: false,
      TrackClickArrState: false,
      state: false,
      headFile: null,
      shipmentMenu: [
        {
          menuName: "Basic info",
          link: "/Shipment/basicInfo",
        },
        {
          menuName: "Routing",
          link: "/Shipment/Routing",
        },
        {
          menuName: "Container",
          link: "/Shipment/Container",
        },
        {
          menuName: "eDoc",
          link: "/Shipment/eDoc",
        },
      ],
      shipmentTitle: "",
      shipmentView: {},
      TrackClickArr: [],
      stepsData: {},
      quotationTypeObj: {},
      tableDatamy: [],
      uploadData: {},
      userinfo: {},
      baseUrl: null,
    };
  },
  created() {
    this.init();
    this.baseUrl = global.baseUrl;
  },
  mounted: function () {},
  methods: {
    init() {
      this.detailDialogType = this.type;
      this.stepsData = this.bookingView.taskLogs;
      this.$session("quotationType").get((value) => {
        value.forEach((item) => {
          this.quotationTypeObj[item.dictValue] = item.dictLabel;
          if (item.children.length > 0) {
            item.children.forEach((item2) => {
              this.quotationTypeObj[item2.dictValue] = item2.dictLabel;
            });
          }
        });
      });
      this.$session("userInfo").get((value) => {
        this.userinfo = value;
        if (this.bookingView.quotation) {
          this.uploadData = {
            createBy: value.email,
            businessType: "1",
            qid: this.bookingView.quotationId,
          };
          this.initData();
        }
      });
    },
    changeFile(file, fileList) {
      this.initData("0");
    },
    upLoadSuccess(res) {
      if (res.result == "true") {
        this.$notify({
          title: res.message,
          type: "success",
          offset: 100,
        });
      } else {
        this.$notify({
          title: res.message,
          type: "error",
          offset: 100,
        });
      }
    },
    upLoadError(res) {
      this.$notify({
        title: res.data.message,
        type: "error",
        offset: 100,
      });
    },
    initData() {
      let that = this;
      let datamy = {
        createBy: that.userinfo.loginCode,
        businessType: "0",
        pageSize: 1,
        pageNo: 1,
        qid: this.bookingView.quotationId,
      };
      that.$ajax
        .cPost(that.source.quotation_api.edoclist, datamy)
        .then((res) => {
          if (res.data.result && res.data.result == "true") {
            if (res.data.list) {
              that.tableDatamy = res.data.list;
            } else {
              that.tableDatamy = [];
            }
          } else {
            that.tableDatamy = [];
          }
        });
    },
    shippingAuthorize() {
      this.loading = true;
      this.$ajax
        .cPost(this.source.booking_api.userAuthorization, {
          quotationId: this.bookingView.quotation.id,
          id: this.bookingView.id,
        })
        .then((res) => {
          if (res.data.result == "true") {
            this.bookingView.bookingStatus = "40";
          }
          this.loading = false;
        });
    },
    confirmQuotationCharges() {
      this.loading = true;
      this.$ajax
        .cPost(this.source.quotation_api.confirmQuotationCharges, {
          quotationId: this.bookingView.quotation.id,
        })
        .then((res) => {
          if (res.data.result == "true") {
            this.bookingView.quotation.amountConfirmFlag = 1;
          }
          this.loading = false;
        });
    },
    clickEdit() {},
    onCopy() {
      this.$notify({
        title: this.$t("lang.SText125"),
        type: "success",
        offset: 100,
      });
    },
    onError() {},
    upLoad(file, fileList) {
      this.headFile = file;
      console.log(file);
      this.hasQuotation = true;
    },
    handleSelect(e) {
      this.handleSelectStr = e;
      this.shipmentTitle = this.shipmentMenu[e].menuName;
      return;
      this.$router.push({ path: this.shipmentMenu[e].link });
    },

    chatHistoryClick() {
      this.detailDialogType = 4;
      this.xianshitype = "";
      if (!this.toSendMsgObj.bid) return;
      this.chatDrawerOpen(this.toSendMsgObj);
    },
    chatDrawerOpen(obj) {
      this.$ajax.cPost(this.source.account_api.toSendMsg, obj).then((res) => {
        if (res.data.result == "true") {
          this.toSendMsg = res.data.msgMain;
        } else {
          this.$notify({
            title: res.data.message,
            type: "error",
            offset: 100,
          });
        }
      });
    },
    delectFile() {
      this.loading = true;
      this.$ajax
        .cPost(this.source.quotation_api.deleteFile, {
          id: this.tableDatamy[0].id,
        })
        .then((res) => {
          if (res.data.result == "true") {
            this.$notify({
              title: res.data.message,
              type: "success",
              offset: 100,
            });
          }
          this.initData("0");
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/base.scss";
.bookingDialog {
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
  }
  .el-dialog__close {
    display: none;
  }
  .booking-left-menu {
    position: fixed;
    top: 200px;
    left: 15%;
    margin-left: -44px;
    .button {
      width: 146px;
      height: 60px;
      transform: rotate(90deg);
      -ms-transform: rotate(90deg); /* IE 9 */
      -moz-transform: rotate(90deg); /* Firefox */
      -webkit-transform: rotate(90deg); /* Safari 和 Chrome */
      -o-transform: rotate(90deg);
      font-size: 20px;
      border: 2px solid $mainColor;
      line-height: 60px;
      background-color: #fff;
      color: $mainColor;
      cursor: pointer;
    }
    .booking-button {
      margin-top: 30px;
    }
    .quotation-button {
      margin-top: 100px;
    }
    .chatHistory-button {
      margin-top: 100px;
    }
    .button:hover,
    .is-check {
      background-color: $mainColor;
      color: $background2;
    }
  }
  .TaskLog-title {
    text-align: left;
    // border-bottom: 2px solid $mainColor;
    margin: auto;
    display: flex;
    padding: 30px 0 10px 0;
    font-size: 30px;
    width: 1020px;
    height: 50px;

    .taskType {
      height: 36px;
      width: 36px;
      line-height: 36px;
      text-align: center;
      font-size: 20px;
      border: 2px solid $mainColor;
      color: $mainColor;
      margin-right: 20px;
      margin-left: 15px;
    }
    .TaskLog-title-ID {
      color: $mainColor;
      line-height: 50px;
      width: 630px;
      .svg-icon {
        font-size: 20px;
        color: $fontColor1;
        padding-left: 30px;
        // margin-top: 20px;
      }
    }
    .statusForhtml {
      height: 50px;
      width: 200px;
      color: $mainColor;
      line-height: 50px;
      font-size: 18px;
    }
    .shippingAuthorization {
      height: 50px;
      width: 200px;
      line-height: 50px;
      text-align: center;
      background-color: $mainColor;
      color: #fff;
      font-size: 18px;
      word-break: normal;
      cursor: pointer;
      display: table;
      .text {
        display: table-cell;
        vertical-align: middle;
      }
    }

    .shippingAuthorization:hover {
      background-color: $background2;
    }
    .upload {
      height: 46px;
      width: 46px;
      border: 2px solid $background2;
      margin-left: 20px;
      .el-icon-download {
        height: 16px;
        width: 16px;
        padding: 8px;
        color: $background2;
      }
    }
    .upload:hover {
      background-color: $background2;
      .el-icon-download {
        color: #fff;
      }
    }
  }

  .shipment-title {
    text-align: left;
    width: 1120px;
    margin: auto;
    display: flex;
    height: 59px;
    font-size: 30px;
    width: 900px;
    border-bottom: 4px solid $mainColor;
    margin-top: 40px;
    justify-content: space-between;
    .taskType {
      height: 36px;
      width: 36px;
      line-height: 36px;
      text-align: center;
      font-size: 20px;
      border: 2px solid $mainColor;
      color: $mainColor;
      margin-right: 20px;
    }
    .TaskLog-title-ID {
      color: $mainColor;
      line-height: 45px;
      display: flex;
    }
    .svg-icon {
      font-size: 20px;
      color: $fontColor1;
      padding-left: 30px;
      padding-top: 10px;
    }
    .shipmentHeader {
      .el-menu .is-active {
        height: 60px !important;
      }
      .el-menu .el-menu-item {
        height: 60px !important;
      }
    }
  }
  .dialog-main {
    min-height: 800px;
    padding-bottom: 50px;
    width: 900px;
    margin: auto;
    .dwSteps {
      margin-top: 70px;
      width: 100%;
    }
    .quotationTitle {
      padding-top: 20px;
    }

    .bookingReviewMainTitle {
      width: 860px;
      margin: auto;
      display: flex;
      justify-content: space-between;
      padding-bottom: 20px;
      margin-bottom: 20px;
      padding-top: 20px;
    }
    .Ocean-freight-FCL {
      height: 60px;
      display: flex;
      .icon-div {
        height: 60px;
        width: 120px;
        display: flex;
        align-items: center;
        border: 2px solid $mainColor;
        .svg-icon {
          height: 30px;
          width: 30px;
          padding: 0 15px;
          color: $mainColor;
        }
      }
      .icon-line {
        width: 1px;
        height: 30px;
        background: $mainColor;
      }
      .title {
        margin-left: 20px;
        font-size: 30px;
        color: $mainColor;
        font-weight: bold;
        line-height: 34px;
        text-align: left;
        width: 210px;
      }
    }

    .shippingAuthorization {
      height: 60px;
      width: 200px;
      line-height: 20px;
      text-align: center;
      background-color: $mainColor;
      color: #fff;
      font-size: 18px;
      word-break: normal;
      cursor: pointer;
      display: table;
      .text {
        display: table-cell;
        vertical-align: middle;
      }
    }
    .unClick {
      background-color: $borderColor1;
      color: $fontColor2;
    }
    .el-upload__text {
      font-size: 40px;
      color: $fontColor2;
      text-align: center;
      padding: 100px 0 150px 0;
      border-bottom: 2px solid $mainColor;
    }
    .offlineFile-line1 {
      font-size: 30px;
      text-align: left;
      margin-top: 30px;
      margin-left: 150px;
      color: $mainColor;
      padding: 30px 0;
    }
    .uploaddiv {
      margin-left: 150px;
    }
    .el-upload {
      display: inline;
    }
    .upload {
      display: flex;
      justify-content: start;
      margin-bottom: 60px;
      .file-type {
        font-size: 14px;
        margin-left: 40px;
        line-height: 48px;
        color: #9b9b9b;
      }
    }
    .offlineFile {
      display: flex;
      justify-content: space-between;
      margin-left: 150px;

      .fileItem {
        display: flex;
        color: $mainColor;
        line-height: 30px;
        .svg-icon {
          height: 30px;
          color: $background2;
          margin-left: 20px;
        }
      }
    }
    .Ocean-freight-FCL2 {
      display: flex;
      .icon-div {
        height: 60px;
        width: 120px;
        display: flex;
        align-items: center;
        background-color: $mainColor;
        .svg-icon {
          height: 30px;
          width: 30px;
          padding: 0 15px;
          color: #fff;
        }
      }
      .icon-line {
        background: #fff;
      }
      .title {
        margin-left: 20px;
        font-size: 30px;
        color: $mainColor;
        font-weight: bold;
        line-height: 34px;
        text-align: left;
      }
    }
  }

  .shipmentHeader {
    height: 30px;
  }

  .shipmentView {
    .topData {
      display: flex;
      border-bottom: 2px solid $mainColor;
      padding: 20px 0;
      .topDataLeft {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .topDataTitle {
          font-size: 60px;
          color: $borderColor1;
          text-align: left;
        }
      }
    }
    .mainData {
      display: flex;
      font-size: 20px;
      line-height: 30px;
      .mainData-title {
        color: $fontColor3;
        width: 180px;
        text-align: right;
        margin-right: 10px;
      }
      .mainData-value {
        color: $mainColor;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 315px;
        text-align: left;
      }
    }
  }
}
</style>