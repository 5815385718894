<template>
  <div class="Task" v-loading="loading">
    <div class="list-header">
      <div class="header-left">
        <div class="form">
          <!-- <div class="search-item">
            <div class="form-item-title">{{$t('lang.BText2')}}</div>
            <el-select
              class="date-card-select"
              v-model="searchForm.status"
              :placeholder="$t('lang.BText80')"
              style="width: 200px"
              clearable
            >
              <el-option
                v-for="item in selectList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div> -->
          <div class="search-item">
            <div class="form-item-title">{{$t('lang.BText2')}}</div>
            <el-select
              class="date-card-select"
              v-model="searchForm.type"
              :placeholder="$t('lang.BText80')"
              style="width: 290px"
              clearable
            >
              <!-- <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option> -->
              <el-option-group
                v-for="group in typeList"
                :key="group.label"
                :label="group.label"
              >
                <el-option
                  v-for="item in group.options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-option-group>
            </el-select>
          </div>
          <div class="search-item range" style="width: 350px">
            <div class="form-item-title">{{$t('lang.BText5')}}</div>
            <el-date-picker
              v-model="searchForm.createDate"
              type="daterange"
              :range-separator="$t('lang.to')"
              value-format="yyyy-MM-dd"
              :start-placeholder="$t('lang.NText300')"
              :end-placeholder="$t('lang.NText209')"
            >
            </el-date-picker>
          </div>
          <div class="search-item range" style="width: 350px">
            <div class="form-item-title">{{$t('lang.NText93')}}</div>
            <el-date-picker
              v-model="searchForm.updateDate"
              type="daterange"
              :range-separator="$t('lang.to')"
              value-format="yyyy-MM-dd"
              :start-placeholder="$t('lang.NText300')"
              :end-placeholder="$t('lang.NText209')"
            >
            </el-date-picker>
          </div>
        </div>

        <div class="search-button">
          <div class="refresh-button" @click="refresh">
            <svg-icon icon-class="refresh"></svg-icon>
          </div>
          <el-button class="searchbutton" @click="query">{{$t('lang.BText108')}}</el-button>
        </div>
      </div>

      <!-- <div class="header-right">
        <el-button @click="clickDraft()">Draft</el-button>
        <el-button @click="clickTemplate()">Template</el-button>
        <el-button class="create" @click="createBooking()"
          >+ Create booking</el-button
        >
      </div> -->
    </div>

    <dwlist
      :url="source.task_api.taskList"
      :query-params="queryForm"
      ref="taskList"
      :initDataFlag="false"
    >
      <!-- <el-table-column prop="type" label="" width="20px">
        <template slot-scope="scope">
          <div class="gantan" v-if="scope.row.Type == 'Q'">!</div>
        </template>
      </el-table-column> -->
      <el-table-column prop="Type" :label="$t('lang.BText8')" width="80px">
        <template slot-scope="scope">
          <div class="taskType">
            {{ scope.row.Type }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="TaskID" :label="$t('lang.NText91')">
        <template slot-scope="scope">
          <div
            class="LinkID"
            v-clipboard:copy="scope.row.TaskID"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            {{ scope.row.TaskID }}<svg-icon icon-class="copy"></svg-icon>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="Summary" :label="$t('lang.BText4')">
        <template slot-scope="scope">
          <div class="LinkID">
            {{ JSON.parse(scope.row.Summary).from }} ->
            {{ JSON.parse(scope.row.Summary).to }}
          </div>
          <div class="LinkID">
            {{ JSON.parse(scope.row.Summary).transportationType }} -
            {{ JSON.parse(scope.row.Summary).getType }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="LinkID" :label="$t('lang.NText92')">
        <template slot-scope="scope">
          <div
            v-if="JSON.parse(scope.row.LinkID).QID"
            class="LinkID"
            v-clipboard:copy="JSON.parse(scope.row.LinkID).QID"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            <div class="text">{{ JSON.parse(scope.row.LinkID).QID }}</div>
            <svg-icon icon-class="copy"></svg-icon>
          </div>
          <div
            v-if="JSON.parse(scope.row.LinkID).BID"
            class="LinkID BOID"
            v-clipboard:copy="JSON.parse(scope.row.LinkID).BID"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            <div class="text">{{ JSON.parse(scope.row.LinkID).BID }}</div>
            <svg-icon icon-class="copy"></svg-icon>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="createDate" :label="$t('lang.BText5')">
        <template slot-scope="scope">
          <div
            class="RepliedDate"
            v-if="$i18n.locale == 'zh_cn' && scope.row.createDate !== ''"
          >
            {{ scope.row.createDate.slice(0, 10) }}
          </div>
          <div
            class="RepliedDate"
            v-if="$i18n.locale == 'en_us' && scope.row.createDate !== ''"
          >
            {{ utils.zhDate_to_enDate(scope.row.createDate).slice(0, 15) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="updateDate" :label="$t('lang.NText93')">
        <template slot-scope="scope">
          <div
            class="RepliedDate"
            v-if="$i18n.locale == 'zh_cn' && scope.row.updateDate !== ''"
          >
            {{ scope.row.updateDate.slice(0, 10) }}
          </div>
          <div
            class="RepliedDate"
            v-if="$i18n.locale == 'en_us' && scope.row.updateDate !== ''"
          >
            {{ utils.zhDate_to_enDate(scope.row.updateDate).slice(0, 15) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="Status" :label="$t('lang.BText2')"> </el-table-column>
      <el-table-column fixed="right" width="250px">
        <template slot-scope="scope">
          <el-button @click="clickView(scope.row)" class="samll-list-info"
            >{{$t('lang.BText18')}}</el-button
          >
          <el-button
            class="samll-list-primary"
            :disabled="scope.row.todo == false"
            @click="clickProcess(scope.row)"
            >{{$t('lang.NText94')}}</el-button
          >
          <!-- <el-button
            class="samll-list-primary"
            @click="clickProcess(scope.row)"
            >Process</el-button
          > -->
          <!-- <el-button class="chat-icon">
            <svg-icon
              icon-class="chat"
              @click="chatIconClick(scope.row)"
            ></svg-icon>
          </el-button> -->
        </template>
      </el-table-column>
    </dwlist>

    <el-dialog
      :visible.sync="logFlag"
      class="createDialog"
      width="70%"
      @close="closeDialog"
      id="bookingDialog"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="Task-title">
        <div class="left">
          <svg-icon icon-class="log" class="svg-icon"></svg-icon>
          {{$t('lang.QText84')}}
        </div>
        <div class="close" @click="closeDialog()">
          <svg-icon class="close-icon" icon-class="close"></svg-icon>
        </div>
      </div>
      <quotation-dialog
        v-if="rowDetail.Type == 'Q' && logFlag"
        :quotationData="JSON.stringify(viewData)"
        :detailDialogType="xianshitype"
      ></quotation-dialog>
      <booking-dialog
        v-if="rowDetail.Type == 'B' && logFlag"
        :bookingView="viewData"
        :type="xianshitype"
      ></booking-dialog>
    </el-dialog>
  </div>
</template> 

<script>
import dwlist from "../../components/dwList/dwlist.vue";
import test from "../../test/test";
import dwSteps from "../../components/dwSteps/dwSteps.vue";
import FillInList from "../Booking/fillInList.vue";
import quotationView from "../Quotation/quotationView.vue";
import detailDialog from "../detailDialog/detailDialog.vue";
import QuotationDialog from "../Quotation/quotationDialog/quotationDialog.vue";
import BookingDialog from "../Booking/bookingDialog.vue";
import utils from "../../utils/util";
export default {
  components: {
    dwlist,
    dwSteps,
    FillInList,
    quotationView,
    detailDialog,
    QuotationDialog,
    BookingDialog,
  },
  data() {
    return {
      queryForm: {},
      rowDetail: "",
      listData: test.taskData,
      searchForm: {},
      toSendMsgObj: {},
      selectList: [
        {
          name: "未完成",
          id: "0",
        },
        {
          name: "已完成",
          id: "1",
        },
      ],
      typeList: [],
      openSearch: false,
      logFlag: false,
      dialogTitle: "",
      review: false,
      edit: false,
      bookingId: null,
      stepsData: {
        head: {
          taskID: "",
          type: "",
        },
        list: [],
      },
      type: "",
      xianshitype: "",
      viewData: {},
      loading: false,
      utils: utils,
    };
  },
  created() {
    if (this.$route.params.editFlag) {
      this.clickEdit();
    }
    if (this.$route.params.logFlag) {
      this.createBooking();
    }
    this.taskSelectData();
  },
  mounted() {
    this.query();
  },
  methods: {
    query() {
      this.queryForm.status = this.searchForm.status;
      this.queryForm.type = this.searchForm.type;
      this.queryForm.createSdate = this.searchForm.createDate
        ? this.searchForm.createDate[0].split("T")[0]
        : "";
      this.queryForm.createEdate = this.searchForm.createDate
        ? this.searchForm.createDate[1].split("T")[0]
        : "";
      this.queryForm.lastUpdateSdate = this.searchForm.updateDate
        ? this.searchForm.updateDate[0].split("T")[0]
        : "";
      this.queryForm.lastUpdateEdate = this.searchForm.updateDate
        ? this.searchForm.updateDate[1].split("T")[0]
        : "";
      this.$refs.taskList.query();
    },
    refresh() {
      this.$set(this.searchForm, "status", "");
      this.$set(this.searchForm, "type", "");
      this.$set(this.searchForm, "createDate", "");
      this.$set(this.searchForm, "updateDate", "");
      this.queryForm.status = "";
      this.queryForm.type = "";
      this.queryForm.createSdate = "";
      this.queryForm.createEdate = "";
      this.queryForm.lastUpdateSdate = "";
      this.queryForm.lastUpdateEdate = "";
      this.$refs.taskList.query();
    },
    clickProcess(data) {
      this.type = "1";
      if (data.Type == "B") {
        this.xianshitype = "3";
      } else {
        this.xianshitype = "2";
      }

      this.rowDetail = data;
      this.gettasklogList();
    },
    taskSelectData() {
      this.$ajax.cPost(this.source.task_api.taskSelect, {}).then((res) => {
        if (res.data.result == "true") {
          this.typeList = res.data.data;
        } else {
          this.typeList = [];
        }
      });
    },
    createBooking() {
      this.type = 0;
      this.dialogTitle = this.$t('lang.BText34');
      this.logFlag = true;
      this.review = false;
    },
    clickType(type) {
      console.log(type);
      this.type = type;
    },
    clickView(data) {
      this.toSendMsgObj = { btype: "40", bid: data.TaskID };
      this.type = "1";
      this.xianshitype = "1";
      this.rowDetail = data;
      this.gettasklogList();
    },
    gettasklogList() {
      let data = {
        bid: this.rowDetail.bid,
        Type: this.rowDetail.Type,
        guide: this.rowDetail.guide,
      };
      this.loading = true;
      this.$ajax.cPost(this.source.task_api.tasklog, data).then((res) => {
        if (res.data.result == "true" && res.data.data) {
          this.viewData = res.data.data;
          if (
            res.data.data.containerType &&
            typeof res.data.data.containerType == "string"
          ) {
            this.viewData.containerType = JSON.parse(
              res.data.data.containerType
            );
          }
          // this.toSendMsgObj = { btype: "20", bid: data.id };
          this.loading = false;
          this.logFlag = true;
        } else {
          this.loading = false;
        }
      });
    },
    clickReview() {
      setTimeout(() => {
        this.toTop();
      }, 10);
      this.type = null;
      this.review = true;
    },
    toTop() {
      var element = document.getElementById("bookingDialog");
      element.scrollIntoView();
    },
    closeDialog() {
      this.logFlag = false;
    },
    clickEdit(data) {
      if (data && data.bookingId) {
        this.bookingId = data.bookingId;
      }
      this.type = null;
      this.dialogTitle = this.$t('lang.SText141');
      this.logFlag = true;
      this.edit = true;
      this.review = false;
    },
    clickDraft() {
      this.$router.push({ path: "/Booking/Draft" });
    },
    clickTemplate() {
      this.$router.push({ path: "/Booking/Template" });
    },
    onCopy() {
      this.$notify({
        title: this.$t('lang.SText125'),
        // message: "这是一条成功的提示消息",
        type: "success",
        offset: 100,
      });
      // this.$notify({
      //   dangerouslyUseHTMLString: true,
      //   message: '<svg-icon icon-class="check" style="height:40px;width:40px"></svg-icon><div class="text" style="color:#808080;font-size:20px">Copy successfully</div>',
      // });
    },
    onError() {},
    chatIconClick(row) {
      this.$emit("drawerClick", { num: row.TaskID });
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/base.scss";
.Task {
  padding-bottom: 30px;
  .gantan {
    color: $background3;
    font-size: 20px;
  }
  .range {
    .el-range-editor {
      .el-range-input,
      .el-range-separator {
        color: #132569;
        border-radius: 0;
        font-family: "montserratregular";
        font-size: 20px;
        line-height: 40px;
      }
    }
  }
  .Task-title {
    background-color: $mainColor;
    height: 150px;
    font-size: 30px;
    display: flex;
    justify-content: space-between;
    color: #fff;

    .left {
      line-height: 150px;
      padding-left: 50px;
    }

    .svg-icon {
      margin-right: 20px;
    }

    .close {
      width: 50px;
      height: 50px;
      margin: 50px;
      cursor: pointer;
      .close-icon {
        width: 50px;
        height: 50px;
        fill: #fff;
        stroke: #fff;
      }
    }
  }
  .LinkID {
    display: flex;
    .text {
      text-decoration: underline;
    }
    .svg-icon {
      fill: $borderColor1;
      stroke: $borderColor1;
      margin-left: 10px;
    }
  }
  .BOID {
    color: $background2;
  }
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
  }
  .el-dialog__close {
    display: none;
  }

  .list-header {
    display: flex;
    justify-content: space-between;
    z-index: 9;
    .header-left {
      display: flex;
      min-width: 1390px;
      width: 100%;
      justify-content: space-between;
      border-bottom: 1px solid $mainColor;
      margin-bottom: 20px;
      padding-bottom: 10px;
      // height: 100px;
      overflow: hidden;
      .form {
        display: flex;
        flex-wrap: wrap;

        .search-item {
          margin-right: 20px;
          margin-top: 15px;
          .form-item-title {
            text-align: left;
            color: $fontColor2;
            line-height: 40px;
          }
          .el-input__inner {
            height: 48px;
            line-height: 48px;
          }
        }
      }

      .search-button {
        display: flex;
        margin-top: 50px;
        .refresh-button {
          height: 44px;
          width: 44px;
          border: 2px solid $mainColor;
          margin-right: 10px;
          .svg-icon {
            height: 20px;
            width: 20px;
            color: $mainColor;
            fill: $mainColor;
            stroke: $mainColor;
            margin-top: 12px;
          }
        }
        .refresh-button:hover {
          border: 2px solid $background2;
          background-color: $background2;
        }
        .searchbutton {
          height: 48px;
          background-color: $mainColor;
          color: #fff;
          border-radius: 0;
          font-family: "montserratregular";
          font-size: 18px;
          border: 2px solid $mainColor;
        }
        .searchbutton:hover {
          border: 2px solid $background2;
          background-color: $background2;
        }
        .icon {
          height: 20px;
          width: 20px;
          color: $mainColor;
          padding: 12px;
          margin: 0 10px;
        }

        .icon:hover {
          height: 26px;
          width: 26px;
          padding: 9px;
        }
      }
    }

    .header-right {
      margin-top: 50px;
      .el-button {
        height: 60px;
        font-size: 18px;
        color: $mainColor;
        border: 2px solid $mainColor;
        border-radius: 0;
        font-family: "montserratregular";
      }
      .el-button:hover {
        background-color: $background2;
        color: #fff;
        border: 2px solid $mainColor;
      }
      .create {
        background-color: $background2;
        color: #fff;
        border: 2px solid $background2;
        width: 210px;
        height: 60px;
        padding: 0;
        text-align: center;
      }
    }
  }

  .router-slid-enter-active,
  .router-slid-leave-active {
    transition: all 0.5s;
  }
  .router-slid-enter,
  .router-slid-leave-to {
    transform: scaleY(0);
    transform-origin: 0 1%;
    opacity: 0;
  }
}
</style>